// Location 
var bool_home = ($('body.home').length) ? true : false;
var bool_intern = (window.location.hostname == 'internet.lfu.bybn.de') ? true : false;

function handle_mobile() {
    // Trigger ist die Sichtbarkeit der dritten Spalte im Offcanvas-Menü. Verborgen < MD
    // Selbe Einstellung wie ".show_desktop/.show_mobile"
    if ($('.frm_oc.third').is(':visible')) {
        // Swiper Pagination Update
        update_swiper_aktuelles_pagination();
        // Umweltatlas Eingebetteter Kartendienst entfernen
        toggle_umweltatlas_ekd('show');
    }
    else {
        // Umweltatlas Eingebetteter Kartendienst entfernen
        toggle_umweltatlas_ekd('hide');
    }
}
$(window).on('resize', handle_mobile);
responsive_table();

var swiper_akt;
$(document).ready(function () {
    // Initialer Aufruf für Elementkontrolle mobile Devices
    handle_mobile();
    //Set link icons in .linkliste
    let d = new LinklistDecorator();
    // Linkliste unter Imagemaps
    imagemap_screenreader_list();
    // Dynamic Accordion
    dyn_bs_accordion();
    // Responsive table
    responsive_table();
    //open Accordion by Hash
    open_accordion_by_hash();

    // Breakpoint Display
    if (bool_intern)
        $('body').prepend('<div class="nav bp_wrapper"><div class="d-block d-sm-none">XS</div><div class="d-none d-sm-block d-md-none">SM</div><div class="d-none d-md-block d-lg-none">MD</div><div class="d-none d-lg-block d-xl-none">LG</div><div class="d-none d-xl-block d-xxl-none">XL</div><div class="d-none d-xxl-block">XXL</div></div>');

    // Elemente für NoJS ein-/ausblenden
    if ($('.js').length) {
        $('.js').show();
        $('.nojs').hide();
    }
    else {
        $('.js').hide();
        $('.nojs').show();
    }

    if ($('#frm_navbar_m').length) {
        animateCSS('#frm_navbar_m', 'slideInUp');

        $('#frm_btn_share').on('click', function (e) {
            e.preventDefault();
            document.getElementById("cmp_sm_icons").scrollIntoView({ behavior: "smooth", block: "center" });
            $('#cmp_sm_icons').addClass('animate__delay-1s');
            animateCSS('#cmp_sm_icons', 'pulse');
        });
        if ($('#cmp_nav_side').length) {
            $('.nav_menu').removeClass('d-none');
            $('#frm_btn_subnav').on('click', function (e) {
                e.preventDefault();
                if ($('#cmp_nav_side').length) document.getElementById("cmp_nav_side").scrollIntoView({ behavior: "smooth", block: "center" });
                $('#frm_btm_nav_m').addClass('animate__delay-1s');
                animateCSS('#frm_btm_nav_m', 'pulse');
            });
        }
        else
            $('.nav_menu').addClass('d-none');
    }

    // Visibility-Slide-Effect
    /* if ($('.displ_indc').length)
    {
        document.addEventListener('scroll', function () {
            const vis_elem = [".displ_indc.wd"];

            vis_elem.forEach(function(element) {
                const vis_state = isInViewport(document.querySelector(element))
                if (vis_state)
                {
                    if (element == ".displ_indc.wd")
                    {
                        $('#cnt_hp_wdienst .card').show();
                    }
                }
            });
        }, {
            passive: true
        }); 
    } */

    // Offcanvas Nav Burger
    if ($('#frm_offcanvas').length) {
        create_burgernav_observer();
        $('.frm_oc.second .lvl1 > li').on('click', function () {
            $('.frm_oc .collapse.show').collapse('hide');
        });
        $('.frm_oc.second a').on('click', function () {
            var active = ($(this).hasClass('active')) ? true : false;
            reset_burgernav_state();
            if (active) {
                $('.frm_oc.second a i.fa-minus').removeClass('fa-minus');
                $(this).children('i').removeClass('fa-minus').addClass('fa-plus');
            }
            else {
                $(this).toggleClass('active');
                $(this).attr('aria-current', 'page');
                $(this).children('i').removeClass('fa-plus').addClass('fa-minus');
            }
        })
        // Marker aktiver Bereich
        var str_first_Dir = getFirstDirectory();
        $('#frm_offcanvas .first a, #frm_offcanvas .second a').filter('[href^="/' + str_first_Dir + '"]').each(function () {
            $(this).css('font-weight', 'bold');
        });
    }

    // Swiper-Carousel breit: Aktuelles
    const int_carousel_interval_sek = 3;

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // Disable Swiper Autoplay
    /* var swiper_auotplay = false;
    if ($('.crsl_pause_icon').length) $('.crsl_pause_icon').children('i').removeClass('fa-pause').addClass('fa-play'); */

    // Enable Swiper Autoplay
     var swiper_auotplay = {
        delay: int_carousel_interval_sek * 1000,
        pauseOnMouseEnter: true
    }; 

    // Only one Slide: Don't initialize swiper, hide elements to navigate the swiper
    if ($("#cmp_swiper_aktuelles .swiper-slide").length == 1) {
        $('.divider').hide();
    } else {
        swiper_akt = new Swiper('#cmp_swiper_aktuelles', {
            grabCursor: true,
            loop: true,
            // hashNavigation:true,

            autoplay: swiper_auotplay,

            pagination: {
                el: '#cmp_swiper_wide .swiper-pagination',
                type: 'bullets',
            },

            navigation: {
                nextEl: '#cmp_swiper_wide .swiper-button-next',
                prevEl: '#cmp_swiper_wide .swiper-button-prev',
            },

            a11y: {
                prevSlideMessage: 'Vorherige Meldung',
                nextSlideMessage: 'Nächste Meldung',
            },
            keyboard: {
                enabled: true,
                // onlyInViewport: false,
            },

            effect: "coverflow",
            coverflowEffect: {
                slideShadows: false
            },

            // Responsive breakpoints
            breakpoints: {
                // when window width is >= :
                992: {
                    // Pagination
                    pagination: {
                        el: ".crsl_titles",
                        type: 'custom',
                        renderCustom: function (swiper, current, total) {
                            $('#cmp_swiper_wide .crsl_titles button').removeClass('active');
                            $('#cmp_swiper_wide .crsl_titles button').eq(current - 1).addClass('active');
                        }
                    },
                }
            },
        });
    }

    // Custom-Navigation-Click
    if ($('.crsl_titles').length)
        $('.crsl_titles button').on('click', function () { swiper_akt.slideTo($(this).attr('data-slide-to')) });
    // Pause/Start Icon
    if ($('.crsl_pause_icon').length) {
        $('.crsl_pause_icon').on('click', function () {
            if ($(this).children('i').hasClass('fa-pause')) {
                $(this).children('i').removeClass('fa-pause').addClass('fa-play');
                swiper_akt.autoplay.stop();
            }
            else {
                $(this).children('i').removeClass('fa-play').addClass('fa-pause');
                swiper_akt.autoplay.start();
            }
        });
        if (swiper_akt != undefined) {
            swiper_akt.on('autoplayStop', function () {
                $('.crsl_pause_icon').children('i').removeClass('fa-pause').addClass('fa-play');
            });
        }
    }

    // Swiper-Carousel: Publikationen
    var swiper_publ = new Swiper("#cmp_swiper_publ", {
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1,
        // hashNavigation:true,

        pagination: {
            el: "#cmp_swiper_publ .swiper-pagination"
        },

        navigation: {
            nextEl: '#cmp_swiper_publ .swiper-button-next',
            prevEl: '#cmp_swiper_publ .swiper-button-prev',
        },

        a11y: {
            prevSlideMessage: 'Vorherige Publikation',
            nextSlideMessage: 'Nächste Publikation',
        },
        keyboard: {
            enabled: true,
            // onlyInViewport: false,
        },

        effect: "coverflow",
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
        },

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= :
            620: {
                slidesPerView: 3,
            },
            // when window width is >= :
            1000: {
                // Pagination
                slidesPerView: 1,
            }
        },
    });

    // Swiper-Carousel breit: Große Cards
    const swiper_th = new Swiper('.cmp_swiper_cards_big .swiper', {
        grabCursor: true,
        loop: true,
        centeredSlides: true,
        slidesPerView: 2,
        spaceBetween: 20,
        // hashNavigation:true,

        pagination: {
            el: '.swiper-pagination',
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        a11y: {
            prevSlideMessage: 'Vorheriges',
            nextSlideMessage: 'Nächstes',
        },
        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= :
            576: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 35,
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
        },
    });


    if (bool_home) {
        if ($('.cmp_head_img .credits').length) {
            if ($('#natur').length)
            {
                var label_natur = document.querySelector('#natur');
                label_natur.addEventListener('animationend', () => {
                    var credits = document.querySelector('.cmp_head_img .credits');
                    credits.style.display = "block";
                    credits.style.setProperty('--animate-duration', '1.5s');
                    animateCSS('.cmp_head_img .credits', 'fadeIn');
                });
            }
            else
            {
                $('.cmp_head_img .credits').show();
            }
        }
    }
    else {
        // Alte Right Teaser aufbereiten
        var bool_glossar = false;
        $('.right_teaser').each(function () {
            if (!$(this).hasClass('cmp_compact'))
                $(this).addClass('cmp_compact');
            if ($(this).find('h1').text() == 'Publikation')
                $(this).addClass('publikationen');
            else if ($(this).find('h1').text() == 'Glossar' || $(this).find('h1').text() == 'Glossary') {
                $(this).addClass('glossar');
                bool_glossar = true;
            }
        });

        // Unterthemen anzeigen Icon
        $('ul.linkliste li a[data-bs-toggle="collapse"]').on('click', function () {
            $(this).parent('li').toggleClass("show");
            $(this).parent('li').toggleClass("hide");
        });

        // Glossar-Tooltipp
        if (bool_glossar) {
            var css_sel_right_teaser_box = '.cmp_compact.glossar';
            var css_sel_glossar_begriff = '.glossar_begriff';
            if ($(css_sel_right_teaser_box + ' h2').length == $(css_sel_glossar_begriff).length) {
                $(css_sel_right_teaser_box).each(function () {
                    if (!$(this).parents('.code_example').length)
                        $(this).children('h1').remove();
                });
                var glossar_content = $(css_sel_right_teaser_box).html();
                // TODO: .replace(/-<br>/g,'') kann irgendwann raus, wenn alle Glossarbegriffe aktualisiert sind!
                glossar_content = glossar_content.replace(/<!-- #BeginLibraryItem "\/Library\/[\.\/_a-z0-9]*" -->[\s]*/g, '').replace(/<!-- #EndLibraryItem -->/g, '####').replace(/-<br>/g, '');
                var arr_defs = glossar_content.split('####');
                var i = 0;
                $(css_sel_glossar_begriff).each(function () {
                    if (!$(this).parents('.code_example').length) {
                        $(this).removeAttr('title');
                        $(this).attr('title', arr_defs[i]);
                        $(this).attr('data-bs-toggle', 'tooltip');
                        $(this).attr('data-bs-html', 'true');
                        $(this).attr('data-bs-animation', 'true');
                        i++;
                    }
                });
            }
            $(function () { $('[data-bs-toggle="tooltip"]').tooltip(); })
        }

        // Abbr-Toolttip
        const tooltipTriggerList = document.querySelectorAll('abbr')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))


        // Lightbox-Images
        $('div[class^="content_zoom_img"]').each(function () {
            // Bei Doppelbildunterschrift nicht den parent-Container, sondern nur die einzelnen Images bearbeiten
            if (!$(this).hasClass("content_zoom_img_doppelunterschrift") && !$(this).parents('.code_example').length) {
                if ($(this).children('a:first-of-type').length && $(this).children('a:first-of-type').children('img').length) {
                    $(this).children('a:first-of-type').attr('data-toggle', 'lightbox');
                    $(this).children('a:first-of-type').attr('data-gallery', 'lfu-gallery');
                    $(this).children('a:first-of-type').attr('data-type', 'image');
                    if ($(this).parent().hasClass("content_zoom_img_doppelunterschrift"))
                        $(this).children('a:first-of-type').attr('data-caption', $(this).parent().text().replaceAll('"', '&quot;'));
                    else
                        $(this).children('a:first-of-type').attr('data-caption', $(this).text().replaceAll('"', '&quot;'));
                }
            }
        });
        const options = {
            keyboard: true,
            gallery: 'lfu-gallery',
            // size: 'xl'
        };
        // TODO: Aufruf optimieren
        document.querySelectorAll('div[class^="content_zoom_"] a[data-gallery]').forEach((el) => el.addEventListener('click', (e) => {
            e.preventDefault();
            const lightbox = new Lightbox(el, options);
            lightbox.show();
            $('[id^=lightboxModal] .ratio').removeClass('ratio-16x9').addClass('lb_bg').removeClass('ratio');
            $('[id^=lightboxModal] .carousel-item').css('transition', 'none');
            $('[id^=lightboxModal] .spinner-border').hide();
        }));


        // Inhaltsverzeichnis linke Seite
        // var css_sel_sidenav_content = '#cmp_nav_side';
        /* var css_sel_bookmarks = '#frm_bookmarks';
        var css_sel_content = '#cmp_content';
        if ($(css_sel_bookmarks).length && $(css_sel_content+' h2').length)  
        {
            if (!$(css_sel_bookmarks+' a').length)
            {
                // $(css_sel_bookmarks).append('<a class="list-group-item list-group-item-action" href="'+css_sel_header+'">Nach Oben</a>');
                var index = 1;
                $(css_sel_content+' h2').each(function() {
                    // var int_bm_length = 28;
                    // var str_text = ($(this).text().length > int_bm_length) ? $(this).text().substring(0,int_bm_length)+"..." : $(this).text();
                    var str_text = $(this).text();
                    // $(css_sel_bookmarks).append('<a class="list-group-item list-group-item-action text-truncate" href="#list-item-'+index+'">'+str_text+'</a>');
                    $(css_sel_bookmarks).append('<li class="text-truncate"><a href="#list-item-'+index+'">'+str_text+'</a></li>');
                    $(this).attr('id','list-item-'+index);
                    index++;
                });
            }

            var scrollSpy = new bootstrap.ScrollSpy(document.body, {
                target: css_sel_bookmarks
            });
        } */

        /* Auto-IDs für Überschriften */
        let bool_Existing_Anchor = ($('.cmp_content > h2 a').length) ? true : false;
        let bool_List = ($('#autosprung').length) ? true : false;
        if (!bool_Existing_Anchor) {
            /* Autosprungmarken bei mehreren h2 */
            let str_sprung_list = "";
            let elem_h2 = $('.cmp_content > h2, .cmp_content .only_intern > h2');
            elem_h2.each(function () {
                if (!$(this).attr('id'))
                    var h_id = "h" + (crc32($(this).html())).toString().substring(0, 5);
                else
                    var h_id = $(this).attr('id');
                $(this).attr('id', h_id);
                if (bool_List) {
                    str_sprung_list += '<li class="sprung"><a title="Sprung nach unten" href="#' + h_id + '">' + $(this).html() + '</a></li>';
                    $(this).append('&nbsp;<a href="#cmp_sprung_nav" title="Zu den Sprungmarken innerhalb der Seite"><i class="fa-sharp fa-regular fa-arrow-up-to-line thm_col_co_basis_85p"></i></a>');
                }
            });
            if (bool_List && str_sprung_list != "")
                $('#autosprung').append('<ul id="cmp_sprung_nav" class="cmp_sprung_nav linkliste mb-4" aria-label="Sprungmarken innerhalb der Seite">' + str_sprung_list + "</ul>");
            else
                $('#autosprung').hide();
        }
        else {
            if (bool_intern)
                $('#cmp_content').prepend('<div class="cmp_box warnbox"><strong>Fehler:</strong> H2 in der Seite enthält bereits einen &lt;a name=&quot;&quot;&gt;-Anker!<br>Bitte dessen name-Attribut als ID für die H2 verwenden! Die Funktion Autosprung nimmt auch vorhandene IDs.');
        }

        // Fix scrolling to hash
        var hash = window.location.hash;
        if (hash) {
            var elem = document.getElementById(hash.replace('#', ''));
            if (elem) $(elem)[0].scrollIntoView(true);
        }


        // Breadcrumb
        $('.cmp_breadcrumb .breadcrumb-item:last-of-type').addClass('active').html($('.cmp_breadcrumb .breadcrumb-item:last-of-type a').text());
        // $('.cmp_breadcrumb .breadcrumb-item a[href=/_elemente_web/index.htm]').parents('.breadcrumb-item').css('border','1px solid red');


        // Farbige Tabellenzeilen bei Unterüberschriften
        $('.cmp_content table:not(.noautorow)').each(function () {
            if (!$(this).parents('.code_example').length) {
                if ($(this).find('.zeilenhead').length) {
                    let state = 0;
                    $(this).find('tr').each(function () {
                        if (!$(this).children('th').length) {
                            if ($(this).children('td').hasClass('zeilenhead')) {
                                state = 0;
                            }
                            else {
                                if (state == 0) {
                                    $(this).addClass('row0');
                                    state = 1;
                                }
                                else {
                                    $(this).addClass('row1');
                                    state = 0;
                                }
                            }
                        }
                    });
                }
            }
        });


        // Durchsuchbare Seitenelemente
        if (!$('.searchfilter').parents('.code_example').length) {
            $('.searchfilter').each(function () {
                var str_id = get_rand_id();
                $(this).addClass('es' + str_id);
                $(this).attr('aria-live', 'polite');
                if ($(this).prop('nodeName') == "TABLE") {
                    var str_element_type = "tbl";
                    var str_element_name = "Tabelle";
                    $(this).find('td').parent().addClass("searchfilter_datarow");
                }
                if ($(this).prop('nodeName') == "DIV") {
                    var str_element_type = "acc";
                    var str_element_name = "nachfolgender Aufzählung";
                }
                if ($(this).prop('nodeName') == "UL") {
                    var str_element_type = "ul";
                    var str_element_name = "nachfolgender Liste";
                }
                $(this).before('<!-- Element-Suchfeld --><div class="cmp_searchfilter mb-2"><label for="sf' + str_id + '" class="form-label small">In ' + str_element_name + ' suchen</label><div class="input-group" aria-label="In ' + str_element_name + ' suchen..."><input class="filter_search ' + str_element_type + ' form-control" id="sf' + str_id + '" type="text" placeholder="Suchen..." aria-controls="' + $(this).attr('id') + '"><button class="btn thm es_del sd' + str_id + '" type="button" id="button-addon2">Suche löschen</button></div>');
                $('.sd' + str_id).on("click", function (e) {
                    e.preventDefault();
                    $('#sf' + str_id).val("");
                    $('#sf' + str_id).keyup();
                });
                $("input#sf" + str_id).on("keyup", function () {
                    var value = $(this).val().toLowerCase();
                    if (str_element_type == "tbl") {
                        $("table.es" + str_id + " tbody tr.searchfilter_datarow").filter(function () {
                            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                        });
                    }
                    if (str_element_type == "acc") {
                        $("div.es" + str_id + " .accordion-item").filter(function () {
                            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                        });
                    }
                    if (str_element_type == "ul") {
                        $("ul.es" + str_id + " li").filter(function () {
                            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
                        });
                    }
                });
            });
        }

        // Map mit Tooltipps 
        tt_imagemaps();
    }

    if ($('.cmp_shariff_icons').length) {
        // Inject Bluesky
        let uri = (encodeURI(location.href) != '') ? encodeURI(location.href) : '';
        let text = '';
        if ($('title').length) { text = $('title').html().replace('LfU Bayern', 'Bayerisches Landesamt für Umwelt'); } else { text = 'Bayerisches Landesamt für Umwelt'; }
        $('li.shariff-button.whatsapp').after('<li class="shariff-button bluesky"><a href="https://bsky.app/intent/compose?text=' + text + ' ' + uri + '" data-rel="popup" rel="nofollow" title="Bei Bluesky posten" role="button" aria-label="Bei Bluesky posten"><span class="fab fa-bluesky"></span><span class="share_text">posten</span></a></li>');
        // Hack for X-Icon
        $('.shariff-button.twitter span.fab').removeClass('fa-twitter').addClass('fa-x-twitter');
        $('.shariff-button.twitter span.share_text').text('posten');
        $('.shariff-button.twitter a').attr('title', 'Bei X posten').attr('aria-label', 'Bei X posten');
        $('.shariff-button.linkedin a').attr('title', 'Bei LinkedIn mitteilen').attr('aria-label', 'Bei LinkedIn mitteilen');
        $('.shariff-button.mail span:first-child').removeClass('fas fa-envelope').addClass('fab fa-sharp fa-regular fa-envelope'); 
        $('.shariff-button.info span:first-child').removeClass('fas fa-info').addClass('fab fa-sharp fa-regular fa-info');
    }

    // Publikationen
    if ($('.cmp_compact.publikationen').length) {
        if (!$('.cmp_compact.publikationen .row').length) {
            $('.cmp_compact.publikationen').contents().wrapAll('<div class="row row-cols-xl-1"></div>');
            $('.cmp_compact.publikationen h1').insertBefore('.cmp_compact.publikationen .row');
        }
        if (!$('.cmp_compact.publikationen .imgcontainer').length)
            $('.cmp_compact.publikationen a img').parent().wrap('<div class="imgcontainer stc mb-2"></div>');
        $('.cmp_compact.publikationen a img').parent().addClass('nounderline');
    }

    // Youtube-Einbindung
    $('.youtube.overlay').click(function (event) {
        event.preventDefault();
        var str_class = $(this).parent().attr('id');
        $('#' + str_class).html($('#yt_overlay_' + str_class).html());

    });


    // Dropdown-Icon-Toggle
    $('#cmp_topnav .nav-item a').on('click', function () {
        var elem = $(this).children('i');
        if (elem.hasClass('fa-plus'))
            $('#cmp_topnav .nav-item i').removeClass('fa-minus').addClass('fa-plus');
        elem.toggleClass('fa-plus');
        elem.toggleClass('fa-minus');
    });

    // Accordion-Funktionen
    // Filterbuttons
    if ($('.buttonfilter').length) {
        $('.buttonfilter').each(function () {
            let str_buttons_filter = '<div class="cmp_acc_filter text-end mb-2"><button class="btn thm open_all" acc-target="#' + ($(this).attr('id')) + '" aria-controls="' + ($(this).attr('id')) + '">Alles aufklappen</button>';
            if ($(this).hasClass('btn_last_change'))
                str_buttons_filter += ' <button class="btn thm show_changed" acc-target="#' + ($(this).attr('id')) + '" title="Änderungen der letzten vier Wochen anzeigen" aria-controls="' + ($(this).attr('id')) + '">Zuletzt geändert</button>';
            str_buttons_filter += '</div>';
            $(this).before(str_buttons_filter);
        });
        // Alle Accordions aufklappen
        $('.open_all').on("click", function (e) {
            e.preventDefault();
            if ($(this).text() == "Alles aufklappen") {
                $(this).text("Alles zuklappen");
                $($(this).attr('acc-target') + ' .accordion-collapse').removeClass("hide").addClass("show");
            }
            else {
                $(this).text("Alles aufklappen");
                $($(this).attr('acc-target') + ' .accordion-collapse').removeClass("show").addClass("hide");
            }
        });
        // Änderungen letzten vier Wochen
        var str_check_date = Math.round((new Date().getTime() / 1000) - 1209600);
        $($('.show_changed').attr('acc-target') + ' .accordion-button').each(function () {
            var arr_stand = $(this).text().split('Stand: ');
            if (typeof arr_stand[1] !== 'undefined') {
                var str_stand = arr_stand[1];
                str_stand = str_stand.substring(0, str_stand.indexOf(")"));
                var arr_date = str_stand.split(".");
                var str_timestamp = new Date(Date.UTC(arr_date[2], arr_date[1] - 1, arr_date[0], 0, 0, 0)).getTime() / 1000;
                if (str_timestamp < str_check_date) {
                    $(this).parents('div.accordion-item').addClass('older');
                }
            }
        });
        $('.show_changed').on("click", function (e) {
            e.preventDefault();
            if ($(this).text() == "Alle anzeigen") {
                $('.accordion div.older').fadeIn();
                $(this).text('Zuletzt geändert');
                $(this).attr('title', 'Änderungen der letzten vier Wochen anzeigen');
            }
            else {
                $('.accordion div.older').fadeOut();
                $(this).text('Alle anzeigen');
                $(this).attr('title', 'Alle Beiträge anzeigen');
            }
        });
    }

    //Opens Accordion Element when Hash = Heading-ID
    function open_accordion_by_hash() {
        let hash = window.location.hash;
        if (hash && $('.accordion').length) {
            $('.collapse').each(function () {
                let labeled = $(this).attr('aria-labelledby');

                if ('#' + labeled === hash) {
                    let accordion = new bootstrap.Collapse($(this), {
                        show: true
                    });
                }
            });
        }
    }


    // Charcounter dyn_forms
    $('.has_charcounter').each(function () {
        $('#rem_len_' + $(this).attr('id')).html($(this).attr('maxlength') - $(this).val().length);
        $(this).bind("keyup keydown", function () {
            var int_maxlength = $(this).attr('maxlength');
            var int_aktlength = $(this).val().length;
            var int_newvalue = int_maxlength - int_aktlength;
            var obj_counter = $('#rem_len_' + $(this).attr('id'));
            obj_counter.text(int_newvalue);
            if (int_newvalue > 0 && int_newvalue < int_maxlength)
                obj_counter.removeClass("limit");
            else if (int_newvalue == 0)
                obj_counter.addClass("limit");
        })
    })

    // Suchefeld
    $('.frm_suche form button').on('click', function () {
        $(this).parents('form').submit();
    });


    // Jonas Formulargenerator: Hack für im backend definierte Breite für Inputs
    if ($('#fg_cmu').length)
    {
        console.log($('#fg_cmu').length);
        
        $('.formular_text input').each(function() {
            $(this).attr('style','');
        });
    } 

});


function getFirstDirectory() {
    var pathArray = window.location.pathname.split('/'); // Split the pathname by '/'
    var filteredArray = pathArray.filter(function (el) {
        return el !== ''; // Remove empty elements from the array
    });

    if (filteredArray.length > 0) {
        return filteredArray[0]; // Return the first directory
    } else {
        return ''; // No directories found
    }
}
function split_Array_by_Index(arr) {
    var evenArray = arr.filter(function (_, index) {
        return index % 2 === 0;
    });

    var oddArray = arr.filter(function (_, index) {
        return index % 2 !== 0;
    });

    return [evenArray, oddArray];
}


function get_rand_id() {
    return (Math.random() + 1).toString(36).substring(7);
}
var crc32 = function (r) { for (var a, o = [], c = 0; c < 256; c++) { a = c; for (var f = 0; f < 8; f++)a = 1 & a ? 3988292384 ^ a >>> 1 : a >>> 1; o[c] = a } for (var n = -1, t = 0; t < r.length; t++)n = n >>> 8 ^ o[255 & (n ^ r.charCodeAt(t))]; return (-1 ^ n) >>> 0 };



function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}



function create_burgernav_observer() {
    let observer_burgernav;
    let options = {
        root: null,
        threshold: 1
        // threshold: [0.1,0.2,0.3,0.4]
    };
    observer_burgernav = new IntersectionObserver(handle_burgernav_observer, options);
    observer_burgernav.observe(document.querySelector(".frm_oc.third"));
}

// Umschalter zwischen den Burgernav-Layouts
function handle_burgernav_observer(entries) {
    entries.forEach((entry) => {
        if (entry.intersectionRatio == 1) {
            // Dreispaltig
            // $('.frm_offcanvas.offcanvas').removeClass('offcanvas-start').addClass('offcanvas-top');
            $('.frm_oc.second .collapse.show').collapse('hide');
            $("#frm_offcanvas .frm_oc.second .nav-link[aria-controls]").each(function () {
                $(this).attr("aria-controls", $(this).attr("href").substr(1) + "_c3");
                $(this).attr("href", "#" + $(this).attr("href").substr(1) + "_c3");
            });
            $(".frm_oc.third .collapse").removeClass('fs-6').addClass('fs-5');

            reset_burgernav_state();
        }
        else {
            // Vertikal
            // $('.frm_offcanvas.offcanvas').removeClass('offcanvas-top').addClass('offcanvas-start');
            $('.frm_oc.third .collapse.show').collapse('hide');
            $("#frm_offcanvas .frm_oc.second .nav-link[aria-controls]").each(function () {
                $(this).attr("aria-controls", $(this).attr("aria-controls").replace("_c3", ""));
                $(this).attr("href", "#" + $(this).attr("aria-controls").replace("_c3", ""));
            });
            $(".frm_oc.third .collapse").removeClass('fs-5').addClass('fs-6');

            reset_burgernav_state();
        }
    });
}
function reset_burgernav_state() {
    $('.frm_oc a.active').removeClass('active');
    $('.frm_oc a[aria-current]').removeAttr('aria-current');
    $('.frm_oc.second a i').removeClass('fa-minus').addClass('fa-plus');
}


/* function create_bottombar_observer() {
    if ($('#cmp_nav_side').length)
    {
        let observer_bottombar;
        let options = {
            root: null,
            threshold: 1
            // threshold: [0.1,0.2,0.3,0.4]
        };
        observer_bottombar = new IntersectionObserver(handle_bottombar_observer, options);   
        observer_bottombar.observe(document.querySelector("#frm_btm_nav_m"));     
    }
} */

function handle_bottombar_observer(entries) {
    entries.forEach((entry) => {
        if (entry.intersectionRatio < 1)
            $('#frm_navbar_m').removeClass('animate__slideOutDown').addClass('animate__slideInUp');
        else
            $('#frm_navbar_m').removeClass('animate__slideInUp').addClass('animate__slideOutDown');
    });
}


function create_totop_observer(selector) {
    if ($(selector).length) {
        let observer_totop;
        let options = {
            root: null,
            threshold: 1
        };
        observer_totop = new IntersectionObserver(handle_totop_observer, options);
        observer_totop.observe(document.querySelector(selector));
    }
}

/* 
function handle_totop_observer(entries, observer_totop) {
    entries.forEach((entry) => {
        if (entry.intersectionRatio == 1)
            $('#frm_to_top').fadeOut();
        else
            $('#frm_to_top').fadeIn();
    });
}

function create_inview_observer(selector) {
    if ($(selector).length)
    {    
        let observer_inview;

        let options = {
            root: null,
            threshold: 1
        };

        observer_inview = new IntersectionObserver(handle_inview_observer, options);
        observer_inview.observe(document.querySelector(selector));
    }
}

function handle_inview_observer(entries, observer_inview) {
    entries.forEach((entry) => {
        console.log(entry.intersectionRatio);
        if (entry.intersectionRatio == 1)
            $("#cnt_hp_wdienst .card").show(); // entry.target.style.display = "block";
        else
            $("#cnt_hp_wdienst .card").hide(); // entry.target.style.display = "none";
    });
} */




const animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });


// Breakpoint-abhängige Funktionen
function update_swiper_aktuelles_pagination() {
    $(document).ready(function () {
        if ($('#cmp_swiper_aktuelles').length && swiper_akt != undefined) {
            swiper_akt.pagination.init();
            swiper_akt.pagination.render();
            swiper_akt.pagination.update();
        }
    });
}



// ------------------------- Funktionen aus vorher verwendeter combined.js
function responsive_table() {
    $('table').not('.noresponsive').each(function () {
        if (!$(this).parents('.code_example').length) {
            var ths = selector_to_array($(this).find('th:not([scope="row"])'));
            $(this).find('tr').each(function () {
                var th_row = "";
                $(this).find('th[scope="row"]').each(function () {
                    th_row = $(this).html() + "; " + th_row;
                });
                var i = 0;
                $(this).find('td:not(.zeilenhead)').each(function () {
                    if ($(this).find('.responsive_table_label').length == 0 && ths[i]) {
                        var old_html = $(this).html();
                        // if ($.trim(old_html) && old_html !== ' ') {
                            var new_html = '<div class="responsive_table_label">' + th_row + ths[i] + ':</div><div class="responsive_table_data">' + old_html + '</div>';
                            $(this).html(new_html);
                        // }
                    }
                    i++;
                });
            });
        }
    });
}
    
function selector_to_array($selector) {
    if ($selector instanceof jQuery == false) $selector = $($selector);
    var catentries = [];
    $selector.each(function () {
        catentries[catentries.length] = $.trim($(this).html()).replace(/<br\s*[\/]?>/gi, " ");
    });
    return catentries;
}
function toggle_umweltatlas_ekd(str_task) {
    if (str_task == "hide") {
        $(".webgiscontainer").each(function () {
            // var ekd_alt_url = $(this).data("ekd_fullsize_url");
            var ekd_alt_url = $(this).attr("data-ekd_fullsize_url");
            if (ekd_alt_url && $(this).hasClass('always_show_ekd') == false && $(this).is(":visible")) {
                $(this).before('<div class="ekd_alttext"><ul class="linkliste"><li class="newin"><a href="' + ekd_alt_url + '" target="_blank" title="Link extern: öffnet ein neues Fenster!">Kartendarstellung: UmweltAtlas Bayern öffnen</a></li></ul></div>');
                $(this).hide();
            }
        });
    }
    else if (str_task == "show") {
        $(".ekd_alttext").remove();
        $(".webgiscontainer").show();
    }
}

$('.cp_to_cb').on('click', function (e) {
    e.preventDefault();
    window.prompt("Zum Kopieren: Strg+C, Enter", $(this).attr('href'));
});

//Parameter aus URL auslesen
$.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        return null;
    }
    else {
        return results[1] || 0;
    }
}

function isEmptyString(str) {
    str = $.trim(str);
    if (str == "" || str == "&nbsp;" || str == null) return true;
    else return false;
}

// Custom Combobox
$(function () {
    if ($(".lfu_combobox").length) {
        $.widget("custom.combobox", {
            options: {
                add_name: true,
                allow_close: false
            },
            _create: function () {
                this.wrapper = $("<span>")
                    .addClass("custom-combobox")
                    .insertAfter(this.element);

                this.element.hide();
                this._createAutocomplete();
                this._createShowAllButton();
            },

            _createAutocomplete: function () {
                var selected = this.element.children(":selected"),
                    value = selected.val() ? selected.text() : "";

                this.input = $("<input>")
                    .appendTo(this.wrapper)
                    .val(value)
                    .attr("title", "")
                    .attr("type", "text")
                    .attr("id", this.element.attr('id') + '__combobox_input')
                    .addClass("custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left")
                    .autocomplete({
                        delay: 0,
                        minLength: 1,
                        source: this._source.bind(this)
                    });

                if (this.options.add_name === true) {
                    input.attr("name", this.element.attr('id'));
                }

                this._on(this.input, {
                    autocompleteselect: function (event, ui) {
                        ui.item.option.selected = true;
                        this._trigger("select", event, {
                            item: ui.item.option
                        });
                    },

                    autocompletechange: "_removeIfInvalid"
                });
            },

            _createShowAllButton: function () {
                var input = this.input,
                    wasOpen = false;
                opt = this.options;

                this.a = $("<a>")
                    .attr("tabIndex", -1)
                    .attr("title", "Alle Einträge zeigen")
                    .appendTo(this.wrapper)
                    .button({
                        icon: "ui-icon-triangle-1-s",
                        showLabel: false
                    })
                    .removeClass("ui-corner-all")
                    .addClass("custom-combobox-toggle ui-corner-right")
                    .on("mousedown", function () {
                        wasOpen = input.autocomplete("widget").is(":visible");
                    })
                    .on("click", function () {
                        input.trigger("focus");

                        if (opt.allow_close === false) {
                            //IE Bugfix: Klick auf Scrollleiste schließt Menü https://forum.jquery.com/topic/setting-height-on-autocomplete
                            input.unbind("blur");
                        }

                        // Close if already visible
                        if (wasOpen) {
                            return;
                        }

                        //Performance boost: Erst bei Click auf Alle zeigen das Menü laden. Dazu minLength auf 0
                        input.autocomplete("option", "minLength", 0);

                        // Pass empty string as value to search for, displaying all results
                        input.autocomplete("search", "");
                    });
            },

            _source: function (request, response) {
                var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
                response(this.element.children("option").map(function () {
                    var text = $(this).text();
                    if (this.value && (!request.term || matcher.test(text)))
                        return {
                            label: text,
                            value: text,
                            option: this
                        };
                }));
            },

            _removeIfInvalid: function (event, ui) {

                // Selected an item, nothing to do
                if (ui.item) {
                    return;
                }

                // Search for a match (case-insensitive)
                var value = this.input.val(),
                    valueLowerCase = value.toLowerCase(),
                    valid = false;
                this.element.children("option").each(function () {
                    if ($(this).text().toLowerCase() === valueLowerCase) {
                        this.selected = valid = true;
                        return false;
                    }
                });

                // Found a match, nothing to do
                if (valid) {
                    return;
                }

                // Remove invalid value
                this.input
                    .val("");
                // .attr( "title", value + " didn't match any item" )
                // .tooltip( "open" );
                this.element.val("");
                // this._delay(function() {
                //     this.input.tooltip( "close" ).attr( "title", "" );
                // }, 2500 );
                this.input.autocomplete("instance").term = "";
            },

            _destroy: function () {
                this.wrapper.remove();
                this.element.show();
            },

            disable: function () {
                this.input.prop('readonly', true);
                this.input.autocomplete("disable");
                this.a.button("option", "disabled", true);
            },
            enable: function () {
                this.input.prop('readonly', false);
                this.input.autocomplete("enable");
                this.a.button("option", "disabled", false);
            }

        });
    }
});

function check_by_typing($selector, validate_function) {
    //setup before functions
    var typingTimer;                //timer identifier
    var doneTypingInterval = 5000;  //time in ms, 5 second for example
    var $input = $selector;

    //on keyup, start the countdown
    $input.on('keyup', function () {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(validate_function($selector), doneTypingInterval);
    });

    //on keydown, clear the countdown 
    $input.on('keydown', function () {
        clearTimeout(typingTimer);
    });
}

/*
 * Change option of depandant target select according to change in source. Results will be retrieved by json providing url.
 * @param  {String} str_source_selector Selector String for Source Input, that provides the value for json_endpoint_url.
 * @param  {String} str_target_select_selector Selector String for the target select field. This will be updated.
 * @param  {Url} json_endpoint_url The URL to the JSON Endpoint. The value from the source value will be appended to the url.
 * @return {} Updates the target select dom
*/
function dependant_select(str_source_selector, str_target_select_selector, json_endpoint_url) {
    $source = $(str_source_selector);
    $target = $(str_target_select_selector);
    $target.attr('aria-live', 'polite');
    if (($source.is('select') || $source.is('input')) && $target.is('select')) {
        var get_results = function () {
            $target.after('<img id="ajax_loading" src="/style/symbole/ajaxloader.gif" />');
            $target.empty();
            $.getJSON(json_endpoint_url + encodeURIComponent($source.val()), function (data) {
                $.each(data, function (i, jsonObject) {
                    var option = $('<option></option>').attr("value", jsonObject.value).text(jsonObject.text);
                    $target.append(option);
                });
                $('#ajax_loading').remove();
            });
        }

        get_results();
        $('body').on("change", str_source_selector, function (e) {
            get_results();
        });
    }
}

// Tooltipps für Imagemaps
function tt_imagemaps() {
    if ($('.imagemap_tt_container').length) {
        if ($('.imagemap_tt_container').each(function () {
            if (!$(this).parents('.code_example').length) {
                var tt_container = $(this);
                var map_name = $(this).find('map').attr('name');
                tt_container.append('<div id="im_tooltip" class="card shadow border-0"></div>');
                var tt_tooltipp = tt_container.children('#im_tooltip');
                var tt_type = $('map[name=' + map_name + ']').attr('class');
                var tt_areas = $('map[name=' + map_name + '] area');
                $(tt_areas).each(function () {
                    var bool_alt_attr = (typeof $(this).attr('alt') !== typeof undefined && $(this).attr('alt') !== false) ? true : false;
                    var bool_title_attr = (typeof $(this).attr('title') !== typeof undefined && $(this).attr('title') !== false) ? true : false;
                    if (!bool_alt_attr) $(this).attr('alt', '');
                    if (!bool_title_attr) $(this).attr('title', '');
                    if ($(this).attr('alt') != "" && $(this).attr('title') != "")
                        $(this).attr('title', '');
                    else if ($(this).attr('alt') == "" && $(this).attr('title') != "") {
                        $(this).attr('alt', $(this).attr('title'));
                        $(this).attr('title', '');
                    }
                    else if ($(this).attr('alt') == "" && $(this).attr('title') == "")
                        $(this).attr('alt', 'Kein Titel vorhanden!');
                });
                $(tt_areas).mouseover(function (e) {
                    let arr_pos = $(this).attr('coords').split(',');
                    let int_left = false;
                    let int_top = false;
                    if ($(this).attr('shape') == 'rect') {
                        int_left = parseInt(arr_pos[2]);
                        int_top = parseInt(arr_pos[3]);
                    }
                    else if ($(this).attr('shape') == 'circle') {
                        int_left = parseInt(arr_pos[0]) + (parseInt(arr_pos[2]) / 2);
                        int_top = parseInt(arr_pos[1]);
                    }
                    else if ($(this).attr('shape') == 'poly') {

                        var result = split_Array_by_Index(arr_pos);
                        var min_x = Math.min.apply(Math, result[0]);
                        var max_x = Math.max.apply(Math, result[0]);
                        var min_y = Math.min.apply(Math, result[1]);
                        var max_y = Math.max.apply(Math, result[1]);
                        int_left = parseInt(max_x);
                        int_top = parseInt(max_y);
                    }
                    else {
                        return true;
                    }

                    if (int_left) {
                        tt_tooltipp.html('');
                        // Positionierung rechter Rand
                        if (int_left > Math.floor(tt_container.width()) - tt_tooltipp.width()) {
                            if ($(this).attr('shape') == 'rect')
                                int_left = parseInt(arr_pos[0]) - tt_tooltipp.width() + (parseInt(arr_pos[2]) - parseInt(arr_pos[0]));
                            else if ($(this).attr('shape') == 'circle')
                                int_left = int_left = parseInt(arr_pos[0]) - (parseInt(arr_pos[2]) / 2) - tt_tooltipp.width();
                            else if ($(this).attr('shape') == 'poly')
                                int_left = parseInt(min_x) - tt_tooltipp.width();
                            else
                                int_left -= tt_tooltipp.width();
                            int_left -= 15;
                        }
                        else
                            int_left += 15;
                        // Positionierung unterer Rand
                        if (int_top > Math.floor(tt_container.height()) - tt_tooltipp.height()) {
                            int_top -= tt_tooltipp.height() + 35;
                            int_top -= 15;
                        }
                        else
                            int_top += 15;
                        if (tt_type == "tt_lueb_karte") {
                            var arr_title = $(this).attr('alt').split('|');
                            tt_tooltipp.append('<div class="card-title thm_col_bg_' + $('body').attr('class') + '"><p>' + arr_title[0] + '</p></div>');
                            tt_tooltipp.append('<div class="card-body"><p>Luftgüteklasse: ' + arr_title[1] + '</p></div>');

                        }
                        else if (tt_type == "tt_ifr_karte") {
                            var arr_title = $(this).attr('alt').split('|');
                            var tt_values = '';
                            if (arr_title[1]) {
                                tt_values += '<dt>' + arr_title[1] + '</dt>';
                                tt_values += '<dd>' + arr_title[2] + '</dd>';
                            }
                            if (arr_title[3]) {
                                tt_values += '<dt>' + arr_title[3] + '</dt>';
                                tt_values += '<dd>' + arr_title[4] + '</dd>';
                            }
                            if (arr_title[5]) {
                                tt_values += '<dt>' + arr_title[5] + '</dt>';
                                tt_values += '<dd>' + arr_title[6] + '</dd>';
                            }
                            if (arr_title[7]) {
                                tt_values += '<dt>' + arr_title[7] + '</dt>';
                                tt_values += '<dd>' + arr_title[8] + '</dd>';
                            }
                            tt_values += '</dl>';
                            tt_tooltipp.append('<div class="card-title thm_col_bg_' + $('body').attr('class') + '"><p>' + arr_title[0] + '</p></div>');
                            tt_tooltipp.append('<div class="card-body"><p>' + tt_values + ' ' + $('#unit').text() + '</p></div>');
                        }
                        else if (tt_type == "tt_lueb_mw" || tt_type == "tt_ifr_mw") {
                            var arr_title = $(this).attr('alt').split('|');
                            tt_tooltipp.append('<div class="card-title thm_col_bg_' + $('body').attr('class') + '"><p>' + arr_title[0] + ', ' + arr_title[1] + '</p></div>');
                            tt_tooltipp.append('<div class="card-body"><p>' + arr_title[2] + ' ' + $('#unit').text() + '</p></div>');
                        }
                        else {
                            tt_tooltipp.append('<div class="card-title thm_col_bg_' + $('body').attr('class') + '"><p>' + $(this).attr('alt') + '</p></div>');
                            if (typeof $(this).attr('data-tt-img') !== typeof undefined && $(this).attr('data-tt-img') !== false)
                                tt_tooltipp.append('<div class="card-img-top"><img src="pic/tooltipp/' + $(this).attr('data-tt-img') + '" class="card-img" alt="" title=""></div>');
                        }
                        tt_tooltipp.css({ 'left': int_left, 'top': int_top }).removeClass('out').addClass('in');
                    }
                });
                // Beim Verlassend der Area schließen
                $(tt_areas).mouseout(function (e) {
                    tt_tooltipp.css({ 'left': 0, 'top': 0 }).removeClass('in').addClass('out');
                });
            }
        }));
    }
}


/*
 * Reads the area tags from a imagemap (map element) and generates a linklist from the href and title attributes.
 * @param  {String} selector_to_map Selector String image map that is used as source for generating the linklist.
 * @param  {String} internal_url URL when containing considered as internal link.
 * @return {} Updates the dom by adding a new .linkliste.generated element with the links.
*/
function imagemap_linklist($selector_to_map, rand_id, internal_url) {
    internal_url = internal_url || "//www.lfu.bayern.de";
    if ($selector_to_map.length > 0 && $selector_to_map.parent().find('.linkliste.generated').length == 0) {
        $($selector_to_map).each(function () {
            var curr = $(this);
            var linklist = '<div id="imagemap_list_' + rand_id + '" class="collapse"><ul class="linkliste generated">';
            curr.find('area').each(function () {
                var linktext = $(this).attr('title');
                if (!linktext) linktext = $(this).attr('alt');
                var intern_extern = 'extern';
                if ($(this).attr('href').indexOf(internal_url) !== -1) intern_extern = 'intern';
                linklist = linklist + '<li class="' + intern_extern + '"><a href="' + $(this).attr('href') + '" title="Link ' + intern_extern + '">' + linktext + '</a></li>';
            });
            linklist = linklist + '</ul></div>';
            curr.parent().append(linklist);
        });
    }
}


/**
 * If no list exists it uses the imagemap_linklist() function to make a linklist after a imagemap, otherwise it uses the existing list.
 * List that can be toggled by a button but is also available for screenreader.
 * @param  {String} trigger_selector optional Selector String for the calss that triggers the generation. Default is the class .imagemap_screenreader_list that must be added to the img tag associated with a map
 * @return {} Updates the dom by adding the linklist and a toggle button.
 */
function imagemap_screenreader_list(trigger_selector) {
    trigger_selector = trigger_selector || '.imagemap_screenreader_list';
    if ($(trigger_selector).length) {
        $("img[usemap].imagemap_screenreader_list").each(function () {
            if (!$(this).parents('.code_example').length) {
                var $current = $(this);
                // Check if .imagemap_screenreader_list_wrapper is already wrapped
                if ($current.parent().is('.imagemap_screenreader_list_wrapper') === false) {
                    var linklist_exists = false;
                    var usemapname = $current.attr('usemap').replace('#', '');
                    var wrappedcontent = null;
                    if ($current.next('h2,h3,h4').next('.linkliste').length) {
                        linklist_exists = true;
                        var h = $current.next('h2,h3,h4');
                        var linkliste = $current.next('h2,h3,h4').next('.linkliste');
                    }
                    else if ($('map[name="' + usemapname + '"]').next('h2,h3,h4').next('.linkliste').length) {
                        linklist_exists = true;
                        var h = $('map[name="' + usemapname + '"]').next('h2,h3,h4');
                        var linkliste = $('map[name="' + usemapname + '"]').next('h2,h3,h4').next('.linkliste');
                    }
                    wrappedcontent = $current.add($('map[name="' + usemapname + '"]')).wrapAll('<div class="imagemap_screenreader_list_wrapper"></div>').parent();
                    var rand_id = get_rand_id();
                    wrappedcontent.append('<button aria-hidden="true" onclick="change_txt(this)" data-bs-toggle="collapse" href="#imagemap_list_' + rand_id + '" class="btn show_desktop thm my-3">Linkliste zeigen</button>');
                    if (linklist_exists === true) {
                        var wrapped_existing_l_h = h.add(linkliste).wrapAll('<div id="imagemap_list_' + rand_id + '" class="collapse"></div>').parent();
                        wrapped_existing_l_h.appendTo(wrappedcontent);
                    }
                    else
                        imagemap_linklist($('map[name="' + usemapname + '"]'), rand_id);
                }
            }
        });
    }
}
function change_txt(button) { if (button.textContent == "Linkliste zeigen") button.textContent = "Linkliste verbergen"; else button.textContent = "Linkliste zeigen"; }

/**
 * Countdown to a date. When reached the content of the DIV with the ID #countdown_completed is displayed
 * @author Ulrich Schäffler
 * @param  {String} targetDivId required ID of the DIV where to put the countdown
 * @param {String} targetDate Date to count down. Format: '2023-12-31T00:00:00'
 * @param {Array} precision Array of precision to count down: ['days', 'hours', 'minutes', 'seconds']
 * @return {} Updates the dom by adding the Countdown to the targetDivId.
 */
function countdown(targetDivId, targetDate, precision, completedDivId) {
    var countdownDiv = document.getElementById(targetDivId);
    countdownDiv.classList.add("d-flex", "flex-column", "flex-sm-row", "align-items-sm-center", "justify-content-between", "gap-3");

    var completedDivId = document.getElementById('countdown_completed');
    completedDivId.style.display = 'none';

    var countDownDate = new Date(targetDate);

    var timeDivs = {};
    var lastTimeValues = {
        years: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null
    };

    precision.forEach(function (unit) {
        var div = document.createElement("div");
        div.id = unit;
        div.classList.add('flex-grow-1', 'py-3', 'bg-primary', 'fs-1', 'text-center');
        timeDivs[unit] = div;
    });

    var isFirstRun = true;

    var intervalId = setInterval(function () {
        var now = new Date();

        // total difference in milliseconds
        var diffMillis = Math.max(countDownDate - now, 0);

        var totalSeconds = Math.floor(diffMillis / 1000);
        var totalMinutes = Math.floor(totalSeconds / 60);
        var totalHours = Math.floor(totalMinutes / 60);
        var totalDays = Math.floor(totalHours / 24);

        var years = precision.includes('years') ? Math.floor(totalDays / 365.25) : 0;
        var days = precision.includes('days') ? totalDays - years * 365.25 : 0;
        var hours = precision.includes('hours') ? totalHours - totalDays * 24 : 0;
        var minutes = precision.includes('minutes') ? totalMinutes - totalHours * 60 : 0;
        var seconds = precision.includes('seconds') ? totalSeconds - totalMinutes * 60 : 0;

        var timeValues = {
            years: Math.floor(years),
            days: Math.floor(days),
            hours: Math.floor(hours),
            minutes: Math.floor(minutes),
            seconds: Math.floor(seconds)
        };

        precision.forEach(function (unit) {
            var div = timeDivs[unit];
            div.innerHTML = timeValues[unit] + " " + unit.charAt(0);

            div.classList.remove('animate__animated', 'animate__flipInX');

            if (isFirstRun || (lastTimeValues[unit] !== null && timeValues[unit] !== lastTimeValues[unit])) {
                div.classList.add('animate__animated', 'animate__flipInX');
            }
            lastTimeValues[unit] = timeValues[unit];
        });

        if (diffMillis <= 0) {
            clearInterval(intervalId);
            countdownDiv.style.setProperty("display", "none", "important")
            completedDivId.style.display = 'block';
            completedDivId.classList.add('animate__animated', 'animate__jackInTheBox');
        } else if (isFirstRun) {
            precision.forEach(function (unit) {
                countdownDiv.appendChild(timeDivs[unit]);
            });
            isFirstRun = false;
        }
    }, 1000);
}

/**
 * Adds corresponding Link Icons to links in linkliste
 * Adds type of document after title, if href is document
 * @author Ulrich Schäffler/Margit Helbing
 * @return {} Updates the dom by adding the classes to ul.linkliste > li > a
 */

class LinklistDecorator {
    constructor() {
        this.regex_blank = new RegExp("^_blank");
        this.regex_collapse = new RegExp('#collapse');
        this.regex_hash = new RegExp("^#");
        this.regex_mailto = new RegExp("^mailto");
        this.regex_file = new RegExp("^file");
        this.regex_https = new RegExp("^https?://");
        this.regex_lfu = new RegExp("^https?://www.lfu.bayern.de");
        this.regex_shop = new RegExp("^https:?//www.bestellen.bayern.de");
        this.link_list = $('ul.linkliste:not(.no_linkicons)');
        this.no_ext = ["htm", "html", "php", "asp", "aspx", "jsp", "do"];

        this.decorate();
    }

    decorate() {
        var self = this;
        self.link_list.each(function () {
            if (!$(this).parents('.code_example').length) {
                $(this).children('li').children('a').each(function () {
                    let anchor = $(this);
                    let parent_li = anchor.parents('li');
                    if (!parent_li.attr("class")) {
                        let href = anchor.attr('href');
                        let target = anchor.attr('target');
                        var domainname_only = self.is_domain_without_path(anchor.attr('href'));

                        if (target && self.regex_blank.test(target)) {
                            parent_li.addClass('newin');
                            anchor.attr('title', 'Link extern: öffnet ein neues Fenster!');
                            return true;
                        } else if (href && self.regex_collapse.test(href)) {
                            parent_li.addClass('show');
                            anchor.on('click', function () {
                                $(this).parent('li').toggleClass("show hide");
                            });
                            return true;
                        } else if (href && self.regex_hash.test(href)) {
                            parent_li.addClass('sprung');
                            anchor.attr('title', 'Link intern');
                            return true;
                        } else if (href && self.regex_mailto.test(href)) {
                            parent_li.addClass('mail');
                            anchor.attr('title', 'E-Mail Link');
                            return true;
                        } else if (href && self.regex_file.test(href)) {
                            parent_li.addClass('file');
                            return true;
                        } else {
                            /* remaining: links with extern/intern */
                            /*  first step: put extension of file after link title */
                            self.addExtensions(anchor, self.no_ext, domainname_only);
                            /* set title-tag + li class of icon */
                            self.addIcons(anchor, parent_li, href, self.no_ext, domainname_only);
                        }
                    }
                });
            }
        });

    }

    // decide if link intern/extern/extern=lfu
    addIcons(anchor, parent_li, href, no_ext, domainname_only) {
        let ext = this.get_ext(href);

        if (href && this.regex_https.test(href)) {
            if (href && this.regex_lfu.test(href)) {
                parent_li.addClass('intern');
                anchor.attr('title', this.is_doc_ext(ext, this.no_ext) + ' intern');
            }
            else {
                parent_li.addClass('extern');
                if (!(anchor.attr('title'))) {
                    if (href && this.regex_shop.test(href)) {
                        anchor.attr('title', 'Link extern: Publikationsshop der Bayerischen Staatsregierung');
                    }
                    else {
                        if (!(domainname_only)) {
                            anchor.attr('title', this.is_doc_ext(ext, this.no_ext) + ' extern');
                        }
                        else {
                            anchor.attr('title', 'Link extern');
                        }

                    }
                }
            }
        } else {
            parent_li.addClass('intern');
            anchor.attr('title', this.is_doc_ext(ext, this.no_ext) + ' intern');
        }
        return true;
    }

    addExtensions(this_a, no_ext, domainname_only) {
        /* check, if href is only domainname oder domain with path */
        var ext = "Link";

        if (!(domainname_only)) {
            var ext = this.get_ext(this_a.attr('href'));
        }

        if (this.is_valid_ext(ext) && (!(domainname_only))) {
            if ($.inArray(ext, no_ext) == -1) {
                var a_html = this_a.html();
                if (a_html.indexOf("<img") < 0) {
                    var li_html = this_a.parent().html();
                    var regex = new RegExp("(?:\\s+|\\xa0+|\\u00A0+|(?:&nbsp;)+|-{1})" + ext, "i");
                    if (regex.test(li_html) === false && regex.test(a_html) === false) {
                        this_a.after('<span class="doc_ext"> - ' + ext.toString().toUpperCase() + '</span>');
                    }
                }
            }
        }
    }

    /* test url, if domain without path */
    /* check this_a, if last charakter is '/' or behind '/' is no '.' */
    is_domain_without_path(href1) {

        let thrd_elem = href1.split('/')[3];
        if ((/^https?:\/\//.test(href1) && ((thrd_elem === undefined)))) {
            return true;
        }
    }

    /* gets extension from href */
    /* if href is domain only, no path behind */
    get_ext(href1) {
        let ext = href1.split("/").pop().split('.').pop();
        if (ext.length > 3 && (/^pdf/.test(ext))) {
            ext = "pdf";
        }
        return ext;
    }

    /* checks if valid extension: max 4 characters, no numerics at the beginning */
    is_valid_ext(ext) {
        if (ext.length < 5 && (!(/^([0-9]+)$/.test(ext)))) {
            return true;
        }
    }

    /* if extension is not in array no_ext */
    is_doc_ext(ext, no_ext) {
        if (this.is_valid_ext(ext) && $.inArray(ext, this.no_ext) == -1) {
            ext = ext.toString().toUpperCase();
        }
        else {
            ext = "Link"
        }
        return (ext);
    }
}

/**
 * Converts headings of same level and following content to a biitstrap accordion if a wrapper with wrapper_class is present
 * @author Ulrich Schäffler
 * @param  {String} wrapper_class Classname of the wrapper that holds the accordion content
 * @return {} Updates the dom by transforming the wrapped content in a bootstrap accordion
 */
function dyn_bs_accordion(wrapper_class = "dyn_accordion_wrapper") {
    if ($('.' + wrapper_class).length) {
        $('.' + wrapper_class).each(function (index, element) {
            if (!$(this).parents('.code_example').length) {
                let $to_be_accordion = $(element);
                let accordionId = `dynamic_accordion${index}`;
                let $new_accordion = $(`<div class="accordion" id="${accordionId}"></div>`).insertBefore($to_be_accordion);

                //copy classes
                let classList = $to_be_accordion.attr("class");
                const classArr = classList.split(/\s+/);
                $.each(classArr, function (index, classname) {
                    if (classname !== wrapper_class) $new_accordion.addClass(classname);
                });

                // Check highest heading level
                let highest_heading_level = 6;
                for (let i = 1; i <= 6; i++) {
                    if ($to_be_accordion.find('h' + i).length > 0) {
                        highest_heading_level = i;
                        break;
                    }
                }

                let heading_selector = 'h' + highest_heading_level;

                let accordion_item_idx = 0;
                $to_be_accordion.find(heading_selector).each(function () {
                    let $this = $(this);
                    let show_classes = '';
                    let aria_expanded = 'false';
                    let button_class = ' collapsed';
                    if ($this.hasClass('show')) {
                        show_classes = ' show';
                        aria_expanded = 'true';
                        button_class = '';
                    }
                    let panelId = `collapse${accordionId}-${accordion_item_idx}`;
                    let headingId = `heading${accordionId}-${accordion_item_idx}`;
                    if ($this.attr('id')) headingId = $this.attr('id');

                    // Create accordion body
                    let accordion_header = `<div class="accordion-item">
                        <${heading_selector} class="accordion-header" id="${headingId}">
                            <button class="accordion-button${button_class}" type="button" data-bs-toggle="collapse" data-bs-target="#${panelId}" aria-expanded="${aria_expanded}" aria-controls="${panelId}">
                                ${$this.text()}
                            </button>
                        </${heading_selector}>
                        <div id="${panelId}" class="accordion-collapse collapse${show_classes}" aria-labelledby="${headingId}" data-bs-parent="#${accordionId}">
                            <div class="accordion-body">
                            </div>
                        </div>
                </div>`;

                    $new_accordion.append(accordion_header);

                    // move content o accordion body
                    let $content = $this.nextUntil(heading_selector);
                    $content.appendTo(`#${panelId} .accordion-body`);

                    accordion_item_idx++;
                });

                // remove original wrapped content
                $to_be_accordion.remove();
            }
        });
    }
};

/**
 * Display random image, excluding current.
 * @param  {Jquery Objaect} img_selector Selector for imga Tag.
 * @param  {Array} arr_images Array of URLs to allowed images.
 * @return {} Updates the dom by changing img src.
*/
function rand_img($img_selector, arr_images) {
    var current = $img_selector.attr('src');
    var arr_images_without_current = arr_images;
    var current_idx = arr_images_without_current.indexOf(current);
    if (current_idx !== -1) {
        arr_images_without_current.splice(current_idx, 1);
    }
    var selected_idx = Math.floor((Math.random() * arr_images_without_current.length));
    $img_selector.attr('src', arr_images_without_current[selected_idx]);
}



